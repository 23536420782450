import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import PageTag from '../components/page-components/PageTag'
import Title from '../components/page-components/Title'
import BlogContainer from '../components/page-components/blog/BlogContainer'

const Container = styled.div`
  width: 100%;
`

const StyledHeader = styled.div`
  width: 100%;
`

class Blog extends Component {
  render() {
    const { data, isFirstLoad, timingOffset, ...rest } = this.props
    const nodeData = data.allPrismicBlog.edges[0].node.data
    const pageTag = nodeData.page_name.text
    const pageTitle = nodeData.title.text

    return (
      <>
        <Helmet>
          <title>Design & Development blog of Abdus Salam</title>
          <meta name="description" content="Articles on UI/UX Design & Front-end Development of website, mobile app, SaaS, dashboard and admin panel." />
        </Helmet>
        <Container>
          <StyledHeader>
            <PageTag delay={timingOffset}>{pageTag}</PageTag>
            <Title delay={timingOffset + 0.3}>{pageTitle}</Title>
            <BlogContainer
              delay={timingOffset + 0.5}
              blogPosts={nodeData.blog_posts}
              {...rest}
            />
          </StyledHeader>
        </Container>
      </>
    )
  }
}

export const query = graphql`
  query {
    allPrismicBlog {
      edges {
        node {
          uid
          data {
            page_name {
              text
            }
            title {
              text
            }
            blog_posts {
              blog_post {
                document {
                  uid
                  data {
                    title {
                      text
                    }
                    date(formatString: "MMMM DD, YYYY")
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Blog
