import React, { Component } from 'react'
import styled from 'styled-components'
import { TweenMax } from 'gsap/TweenMax'
import { common } from '../../utility/common'
import media from '../../utility/media'
import theme from '../../utility/theme'

const StyledPageTag = styled.div`
  font-family: ${theme.fontFamily};
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 1.6px;
  text-align: left;
  text-transform: uppercase;
  color: ${theme.colorPrimary};
  margin-bottom: 10px;
  @media (min-width: ${media.md}) {
    margin-bottom: 18px;
  }
`
class PageTag extends Component {
  componentDidMount() {
    this.pageTagTween = TweenMax.from(this.pageTag, common.duration, {
      autoAlpha: 0,
      x: -25,
      ease: common.easing.easeOut,
      delay: this.props.delay,
    })
  }

  render() {
    return (
      <StyledPageTag innerRef={div => (this.pageTag = div)}>
        {this.props.children}
      </StyledPageTag>
    )
  }
}

export default PageTag
