import React, { Component } from 'react'
import styled from 'styled-components'
import { TweenMax } from 'gsap/TweenMax'
import theme from '../../utility/theme'
import { common } from '../../utility/common'
import media from '../../utility/media'

const StyledTitle = styled.div`
  font-family: ${theme.fontTitle};
  color: #fff;
  font-size: 25px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 2.4px;
  text-align: left;
  @media (min-width: ${media.sm}) {
    font-size: 28px;
  }
  @media (min-width: ${media.md}) {
    font-size: 35px;
  }
  @media (min-width: ${media.lg}) {
    font-size: 45px;
  }
`

class Title extends Component {
  componentDidMount() {
    this.titleTween = TweenMax.from(this.title, common.duration, {
      autoAlpha: 0,
      x: -25,
      ease: common.easing.easeOut,
      delay: this.props.delay,
    })
  }

  render() {
    return (
      <StyledTitle innerRef={div => (this.title = div)}>
        {this.props.children}
      </StyledTitle>
    )
  }
}

export default Title
