import React, { Component } from 'react'
import styled from 'styled-components'
import Reveal from 'react-reveal'
import { TweenMax } from 'gsap/TweenMax'
import { common } from '../../../utility/common'
import theme from '../../../utility/theme'
import media from '../../../utility/media'

const BlogItems = styled.div`
  width: 100%;
  padding: 60px 0;
`

const BlogItem = styled.div`
  margin-bottom: 30px;
`

const BlogLink = styled.a`
  display: block;
  padding: 50px;
  background: ${theme.colorGrey};
  color: ${theme.colorSecondary};
  @media (max-width: ${media.md}) {
    padding: 20px;
  }
`

const BlogTitle = styled.h3`
  color: #fff;
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 7px;
  @media (max-width: ${media.md}) {
    font-size: 20px;
  }
`

const BlogDate = styled.div`
  font-size: 14px;
  font-weight: 500;
  width: 100%;
`

class BlogContainer extends Component {
  constructor() {
    super()
    this.blogItemArr = []
  }

  componentDidMount() {
    this.blogItemsTween = TweenMax.from(this.blogItems, common.duration * 2, {
      autoAlpha: 0,
      x: -25,
      ease: common.easing.easeOut,
      delay: this.props.delay,
    })
  }

  animateBlogItem = index => {
    this.blogItemTween = TweenMax.from(
      this.blogItemArr[index],
      common.duration * 3,
      {
        autoAlpha: 0,
        x: -25,
        ease: common.easing.easeOut,
      }
    )
  }

  render() {
    const { blogPosts, startPageChangingHandler, ...rest } = this.props
    return (
      <BlogItems innerRef={div => (this.blogItems = div)}>
        {blogPosts.map((post, index) => {
          const postData = post.blog_post.document[0].data
          const slug = '/blog/' + post.blog_post.document[0].uid
          const date = post.blog_post.document[0].last_publication_date
          return (
            <Reveal key={index} onReveal={() => this.animateBlogItem(index)}>
              <BlogItem innerRef={div => (this.blogItemArr[index] = div)}>
                <BlogLink
                  href={slug}
                  onClick={e => {
                    e.preventDefault()
                    startPageChangingHandler(slug)
                  }}
                >
                  <BlogTitle>{postData.title.text}</BlogTitle>
                  <BlogDate>{date}</BlogDate>
                </BlogLink>
              </BlogItem>
            </Reveal>
          )
        })}
      </BlogItems>
    )
  }
}

export default BlogContainer
